<template>
  <draggable x v-on="$listeners" v-bind="{...$attrs,...dragOptions}">
    <slot></slot>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "BaseDraggable",
  components: { draggable },
  computed: {
    dragOptions() {
      return {
        animation: 300,
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>

<style></style>
