import { BaseError } from "../base/BaseError";
import { Notify } from "../notify/Notify";

export class BadRequestError extends BaseError {
    message ="خطأ في الطلب،الرجاء المحاولة مرة أخرى";
    dispatchError() {
        Object.values(this.data.errors ?? []).forEach(error => {
            if (error) {
                Notify.showMessage("error", error);
            }
          });
      }
}
