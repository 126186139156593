import BaseService from "./BaseService";

export default class BaseStore {
  static promiseHandler(promise, onResolved, onRejected) {
    return new Promise((resolve, reject) => {
      promise()
        .then((res) => {
          let { data, status } = res;
          return BaseService.resolveStatusResponse(status, res).then(() => {
            if (onResolved) {
              onResolved(data);
            }
            return resolve(res);
          });
        })
        .catch((err) => {
          if (onRejected) {
            onRejected(err);
          }
          reject(err);
        });
    });
  }
}
