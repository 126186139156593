import { BaseError } from "../base/BaseError";
import { Notify } from "../notify/Notify";

export class RequestError extends BaseError {
  message ="خطأ في الطلب،الرجاء المحاولة مرة أخرى";
  
  dispatchError() {
    Notify.showMessage("error", this.message);
  }
}
