<template>
  <div class="hidden" :class="type" v-atFocus="'showElement'">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BaseTransitionWrapper",
  props: {
    type: {
      type: String,
      default: "bottom",
    },
  },
  directives: {
    atFocus: {
      isLiteral: true,
      inserted: (el, binding) => {
        let f = () => {
          let rect = el.getBoundingClientRect();
          let inView =
            rect.width > 0 &&
            rect.height > 0 &&
            rect.top >= 0 &&
            rect.bottom <=
              (window.innerHeight || document.documentElement.clientHeight);
          if (inView) {
            el.classList.add(binding.value);
            window.removeEventListener("scroll", f);
          }
        };
        window.addEventListener("scroll", f);
        f();
      },
    },
  },
};
</script>

<style scoped>
.hidden {
  opacity: 0;
}

.right {
  transform: translate(50px, 0);
}

.left {
  transform: translate(-50px, 0);
}

.bottom {
  transform: translate(0, 50px);
}

.showElement {
  opacity: 1;
  transform: translate(0, 0);
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  transition: all 1s ease-out;
}
</style>
