<template>
  <v-btn class="ml-2" :icon="isIcon" v-on="$listeners" v-bind="$attrs">
    <v-tooltip bottom :open-delay="100">
    <template v-slot:activator="{ on: tooltip }">
      <v-icon v-on="{ ...tooltip }" class="mx-1" :color="isIcon?'primary':'white'">
        {{ icon }}
      </v-icon>
    </template>
    <span>{{ actionName }}</span>
  </v-tooltip>
  <label v-if="!isIcon">{{ actionName }}</label>
  <slot></slot>
</v-btn>
</template>
<script>
export default {
  name: "BaseTableItemAction",
  props: {
    icon: String,
    actionName: String,
    isIcon:{//to determine if a btn should be displayed as an icon only or as a normal btn 
      type:Boolean,
      default:true
    }
  },
};
</script>
