import { Notify } from "../notify/Notify";
import { BaseError } from "../base/BaseError";

export class UnprocessableEntityError extends BaseError {
  message = "الرجاء إدخال البيانات بالشكل الصحيح";

  dispatchError() {
    Object.values(this.data.errors ?? []).forEach(error => {
      if (error) {
        // items.forEach(item => {
          Notify.showMessage("error", error);
        // });
      }
    });
  }
}
