import { EventBus } from "../../../main";

export class Notify {
  /**
   * @return string
   * @desc get available message
   * @author karam mustafa
   * */
  static getMessage(code) {
    let codeAvailable = {
      400: "خطأ في الطلب،الرجاء المحاولة مرة أخرى",
      401: "انتهت صلاحية جلستك، الرجاء تسجيل الدخول مرة أخرى",
      403: "لا يمكن إتمام الطلب لعدم امتلاك الصلاحيات اللازمة",
      301:"خطأ في الطلب،الرجاء المحاولة مرة أخرى",
      422:  "الرجاء إدخال البيانات بالشكل الصحيح",
      500:  "خطأ في الخادم،الرجاء المحاولة مرة أخرى",
    };
    return codeAvailable[code] != null || undefined
      ? codeAvailable[code]
      : code;
  }

  static showMessage(type, message) {
    EventBus.$notify({
      group: "public",
      type: type,
      title: message,
      duration: 5000
    });
  }
}
