<script>
export default{
    methods: {
    formatCurrency(amount) {
      const formatter = new Intl.NumberFormat("ar-sy", {
        style: "currency",
        currency: "SYP",
      });
      return formatter.format(amount);
    },
}
}
</script>