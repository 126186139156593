<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    :persistent="persistent"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card elevation="2" class="pa-5">
      <v-btn
        v-if="!persistent"
        text
        dense
        outlined
        rounded
        class="close-btn"
        @click="close()"
        ><v-icon>mdi-close</v-icon></v-btn
      >
      <v-card-title class="mt-3">
        <v-spacer></v-spacer>
        <slot name="title"></slot>
        <v-spacer></v-spacer>
      </v-card-title>
      <slot name="body"></slot>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions"></slot>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BaseDialog",
  props: {
    showCondition: Boolean,
    persistentCondition: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: this.showCondition,
      persistent: this.persistentCondition,
    };
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
    persistentCondition: {
      immediate: true,
      handler() {
        this.persistent = this.persistentCondition;
      },
    },
  },
  methods: {
    close() {
      if (!this.persistent) this.$emit("closed");
    },
  },
};
</script>

<style scoped>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
.close-btn {
  position: absolute;
  left: 10px;
  top: 10px;
}
</style>
