import { ErrorRegistration } from "./ErrorRegistration";
import { FailedRequestError } from "./FailedRequestError";
// import { BadRequestError } from "./BadRequestError";


export class ResponseErrorWrapper {
  
  constructor(error) {
    this.error = error;
    this.data = this.registerResponse(error);
    return this.wrapError();
  }

  registerResponse(error) {
    return error?.response?.data;
  }

  wrapError() {
    let errorClass = new ErrorRegistration().getClassFromStatusCode(
      this?.error?.response?.status
    );
    return errorClass != undefined
      ? errorClass?.initData(this.error, this.data).dispatchError()
      // : new BadRequestError().initData(this.error, this.data).dispatchError();
      :new FailedRequestError().initData(this.error, this.data).dispatchError();//case no status for the error response
  }

}
