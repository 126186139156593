<template>
  <div>
    <TopNav></TopNav>
    <SideNav></SideNav>
    <router-view class="page-view" />
    <LoadingProgress></LoadingProgress>
    <NotificationAlert></NotificationAlert>
    <LogoutDialog />
  </div>
</template>
<script>
import TopNav from "../components/navs/TopNav.vue";
import SideNav from "../components/navs/SideNav";
import LoadingProgress from "../components/loader/LoadingProgress";
import NotificationAlert from "../components/notifications/NotificationAlert";
import LogoutDialog from "@/modules/auth/components/LogoutDialog.vue";

export default {
  components: {
    NotificationAlert,
    LoadingProgress,
    SideNav,
    TopNav,
    LogoutDialog
},
};
</script>

<style scoped>
.page-view {
  margin-top: 35px;
  min-height: 80vh;
}

</style>
