import { useAuthStore } from "@/modules/auth/store";
const PermissionCanDirective = {
  bind: function (el, binding, vnode) {
    var authStore = useAuthStore();
    var permissions = authStore.permissions;
    if (
      !permissions ||
      (binding.arg &&
        !permissions.includes(binding.arg.replace(/'/g, "").replace(/"/g, "")))
    ) {
      // vnode.elm.style.display = "none";

      // replace HTMLElement with comment node
      const comment = document.createComment(" ");
      Object.defineProperty(comment, "setAttribute", {
        value: () => undefined,
      });
      vnode.elm = comment;
      vnode.text = " ";
      vnode.isComment = true;
      vnode.context = undefined;
      vnode.tag = undefined;
      vnode.data.directives = undefined;

      if (vnode.componentInstance) {
        vnode.componentInstance.$el = comment;
      }

      if (el.parentNode) {
        el.parentNode.replaceChild(comment, el);
      }
    }
  },
};
export default PermissionCanDirective;
