<template>
  <v-chip-group
    class="mb-5"
    v-model="selectedTagIndex"
    column
    @change="emitChanged()"
  >
    <v-chip filter color="accent" v-for="tag in tags" :key="tag.id">
      {{ tag.name }}
    </v-chip>
  </v-chip-group>
</template>

<script>
export default {
  name: "BaseFilterChips",
  props: {
    tags: Array,
  },
  data() {
    return {
      selectedTagIndex: null,
    };
  },
  computed: {
    getTagItemFromIndex() {
      return this.tags[this.selectedTagIndex];
    },
  },
  methods: {
    emitChanged() {
      this.$emit("filtered", this.getTagItemFromIndex);
    },
  },
};
</script>
